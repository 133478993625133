export const environment = {
  production: true,
  baseUrl: 'https://api-ecomodule.procredit-group.com',
  appInsights: {
    instrumentationKey: '142a21e4-0cdf-4be8-85e0-b3f3e6b325a7',
  },
  msalConfig: {
    uri: 'https://graph.microsoft.com/v1.0/me',
    authority: 'https://login.microsoftonline.com/',
  },
  languageBarVisibility: false,
  isImportEnabled: true,
  isFileUploadEnabled: true,
  enablePB: false,
  maxNumber: 1_000_000_000_000_000,
};
